@import url('https://fonts.googleapis.com/css2?family=Zen+Kurenaido&display=swap');

.TopField{
    position: relative;
    display: flex;
    width: 100%;
    padding-top: 140px;
    padding-bottom: 140px;
    background-color: #d0f3d5;
}

.LeftBox {
    width: 50%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.RightBox{
    font-family: "Zen Kurenaido", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    width: 40%;
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.349);
    box-shadow: 0px 0px 15px -5px #777777;
}
.Avatar img{
    width: 300px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px 4px #777777;
}

.Logo img{
    width: 320px;
}


.customshapedividerbottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.customshapedividerbottom svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 107px;
    transform: rotateY(180deg);
}

.customshapedividerbottom .shapefill {
    fill: #FFFFFF;
}