
body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p .Text{
  font-family: "Zen Kurenaido", sans-serif;
  font-weight: 400;
  font-style: normal;
}
