@import url('https://fonts.googleapis.com/css2?family=Jersey+15&family=Rajdhani:wght@300;400;500;600;700&family=Yomogi&family=Zen+Kurenaido&display=swap');

.HeaderField{
    position: relative;
    width: 100%;
}
.Hunberger {
    position: fixed;
    background: #92cf69;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    top: 10px;
    right: 10px;
    z-index: 150;
}

.HunbergerArea {
    transition: all .6s;
    width: 50px;
    height: 50px;
}

.HunbergerArea span {
    display: inline-block;
    transition: all .4s;
    position: absolute;
    left: 14px;
    height: 3px;
    border-radius: 2px;
    background: #fff;
    width: 45%;
}

.HunbergerArea span:nth-child(1){
    top: 15px;
}


.HunbergerArea span:nth-child(2){
    top: 23px;
}


.HunbergerArea span:nth-child(3){
    top: 31px;
}


.LinkField {
    position: fixed;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 100;
    right: 0;
    height: 100vh;
    background-color:rgba(0, 0, 0, 0.712);
    animation: slideAnime 800ms;
    overflow-y: hidden;
}

.LinkFieldClose {
    position: fixed;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 0%;
    z-index: 100;
    right: 0;
    height: 100vh;
    background-color:rgba(0, 0, 0, 0.712);
    animation: slideAnimeReverse 800ms;
    overflow-y: hidden;
}

@keyframes slideAnime{
    0% {
        width: 0%;
        opacity: 0;
    }
    100%{
        width: 100%;
        opacity: 1;
    }
}

@keyframes slideAnimeReverse{
    0% {
        width: 100%;
        opacity: 1;
    }
    100%{
        width: 0%;
        opacity: 0;
    }
}

.Link {
    font-family: "Rajdhani", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 22px;
    margin-top: 30px;
}

.Link a{
    text-decoration: none;
    color: rgb(72, 192, 48);
    transition: all 0.2s ease;

}
.Link a:hover{
    color: rgb(162, 231, 148);
}

.SNSField{
    padding-top: 100px;
    display: flex;
    width: 10%;
    justify-content: space-around;
    font-size: 32px;
    color: whitesmoke;
}

.SNSIcon{
    cursor: pointer;
}


.MenuLogo{
    font-family: "Rajdhani", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: white;
    border-bottom: 1px solid rgb(136, 132, 132);
    font-size: 26px;
}