@import url('https://fonts.googleapis.com/css2?family=Jersey+15&family=Rajdhani:wght@300;400;500;600;700&family=Yomogi&family=Zen+Kurenaido&display=swap');
.SkillField{
    width: 100%;
    margin-top: 50px;
    position: relative;
    margin-bottom: 140px;
}

.Logo{
    font-family: "Rajdhani", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 55px;
    color: #777777;
    padding: 20px;
}

.SkillLogo{
    font-family: "Rajdhani", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 30px;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
    margin-top: 5px;
    font-size: 38px;
    color: #777777;

}

.SkillContents{
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
    color: #777777;
}

.Skill{
    width: 30%;
    height: 240px;
    background-color: rgba(234, 238, 241, 0.308);
    border: 1px solid rgb(199, 197, 197);
    border-radius: 2px;
    box-shadow: 0px 0px 13px -5px #777777;
}

.Contents{
    font-size: 16px;
    padding: 10px;
    border-top: 1px solid gray;
    width: 90%;
    margin: 0 auto;
}