@import url('https://fonts.googleapis.com/css2?family=Jersey+15&family=Rajdhani:wght@300;400;500;600;700&family=Yomogi&family=Zen+Kurenaido&display=swap');

.TopWorkField{
    width: 100%;
    padding-bottom: 140px;
    background-color: 
    #d0f3d5;
}
.Logo{
    font-family: "Rajdhani", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 55px;
    color: #777777;
    padding: 20px;
}